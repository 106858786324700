import { useQuery } from "@apollo/client";
import { useState } from "react";
import { CompleteLangsArray } from "../../../../GlobalFunctions/helper";
import { GET_PRODUCT } from "../../Queries/products";
import dayjs from "dayjs";

const UseGetProduct = (selectedProductID, lang, CallBack) => {
    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [allManufacturers, SetAllManufacturers] = useState([]);
    const [allVats, SetAllVats] = useState([]);

    const { loading, error } = useQuery(GET_PRODUCT, {
        variables: {
            productID: selectedProductID,
            lang,
        },
        fetchPolicy: "network-only",
        onCompleted: (d) => {
            const emptyLang = {
                name: "",
                description: "",
                commonPrice: 0,
                price: 0,
                discount: 0,
                isDiscountPercentage: false,
                metaTitle: "",
                seoUrl: "",
                metaDescription: "",
                hName: "",
                hNameWithParameters: "",
                hCategory: "",
                hDescription: "",
            };

            if (selectedProductID != 0) {
                let langs = CompleteLangsArray(d.ProductWithLangs.langs, d.AllLanguageMutations, emptyLang);

                CallBack({
                    selectedLang: lang,
                    manufacturerID: d.ProductWithLangs.manufacturerID,
                    productDimensionTypeID: d.ProductWithLangs.productDimensionTypeID,
                    showOnEshop: d.ProductWithLangs.showOnEshop,
                    availableForCart: d.ProductWithLangs.availableForCart,
                    hideCovers: d.ProductWithLangs.hideCovers,
                    hideCorpus: d.ProductWithLangs.hideCorpus,
                    vatID: d.ProductWithLangs.vatID,
                    hDeliveryTime: d.ProductWithLangs.hDeliveryTime,
                    hManufacturer: d.ProductWithLangs.hManufacturer,
                    dontOverwritePriceWithVariant: d.ProductWithLangs.dontOverwritePriceWithVariant,
                    langs: langs,
                    productPhotos: GetProductPhotos(d.ProductWithLangs.productPhotos),
                    selectedCategories: GetSelectedCategories(d.ProductWithLangs.links),
                    selectedTickLabels: GetSelectedTickLabels(d.ProductWithLangs.tickLabels),
                    selectedLabels: GetSelectedLabels(d.ProductWithLangs.labels),
                    productDimensionType: GetProductDimensionType(d.ProductWithLangs.productDimensionType, d.ProductWithLangs.productDimensionTypeSizes),
                    selectedParameters: GetSelectedParameters(d.ProductWithLangs.parameters, d.WholeParameterLangValues),
                    selectedExcludedCovers: GetExcludedCovers(d.ProductWithLangs.excludedCovers),
                    selectedCoversPercentage: GetCoverPercentages(d.ProductWithLangs.coverPercentages),
                });
            } else {
                let langs = CompleteLangsArray([], d.AllLanguageMutations, emptyLang);

                CallBack({
                    langs: langs,
                });
            }

            SetAllManufacturers(d.AllManufacturers);
            SetAllLanguageMutations(d.AllLanguageMutations);
            SetAllVats(d.AllVats);
        },
    });

    const GetProductPhotos = (productPhotos) => {
        var newProductPhotos = [];

        for (let val of productPhotos) {
            newProductPhotos.push({
                productPhotoID: val.productPhotoID,
                name: val.name,
                isMain: val.isMain,
                priority: val.priority,
                isUpdated: false,
            });
        }

        return newProductPhotos;
    };

    const GetSelectedCategories = (links) => {
        var categories = [];
        for (let val of links) {
            categories.push({
                linkID: val.linkID,
                isMain: val.isMain,
                name: val.link ? val.link.name : "ODKAZ SMAZÁN",
            });
        }

        return categories;
    };

    const GetSelectedTickLabels = (tickLabels) => {
        var newTickLabels = [];
        for (let val of tickLabels) {
            newTickLabels.push({
                tickLabelID: val.tickLabelID,
                name: val.tickLabel ? val.tickLabel.name : "ŠTÍTEK SMAZÁN",
            });
        }

        return newTickLabels;
    };

    const GetSelectedLabels = (labels) => {
        var newLabels = [];
        for (let val of labels) {
            newLabels.push({
                labelID: val.labelID,
                useDates: val.useDates,
                dateFrom: val.dateFrom == "0000-00-00 00:00:00" ? "" : dayjs(val.dateFrom).format("YYYY-MM-DD"),
                dateTo: val.dateTo == "0000-00-00 00:00:00" ? "" : dayjs(val.dateTo).format("YYYY-MM-DD"),
                name: val.label ? val.label.name : "ŠTÍTEK SMAZÁN",
            });
        }

        return newLabels;
    };

    const GetProductDimensionType = (prodDimenstionType, sizes) => {
        var productDimensionType = null;

        if (prodDimenstionType && prodDimenstionType.photo) {
            var sizePositions = [];

            for (let val of sizes) {
                sizePositions.push({
                    value: val.value,
                    productDimensionTypeSizePositionID: val.productDimensionTypeSizePositionID,
                    x: val.productDimensionTypeSizePosition.x,
                    y: val.productDimensionTypeSizePosition.y,
                });
            }

            productDimensionType = {
                photo: prodDimenstionType.photo,
                productDimensionTypeID: prodDimenstionType.productDimensionTypeID,
                name: prodDimenstionType.name,
                sizePositions: sizePositions,
            };
        }
        return productDimensionType;
    };

    const GetExcludedCovers = (covers) => {
        var newExcludedCovers = [];
        for (let val of covers) {
            newExcludedCovers.push(val.coverID);
        }

        return newExcludedCovers;
    };

    const GetCoverPercentages = (covers) => {
        var newCoverParameters = [];
        for (let val of covers) {
            newCoverParameters.push({
                coverID: val.coverID,
                percentage: val.percentage,
            });
        }

        return newCoverParameters;
    };

    const GetSelectedParameters = (parameters, wholeParameterValues) => {
        var newParameters = [];
        for (let val of parameters) {
            var values = [];
            var checkParam = null;

            if (val.parameter) {
                for (let i in newParameters) {
                    if (newParameters[i].parameterID == val.parameterID) {
                        checkParam = i;
                        break;
                    }
                }

                if (!checkParam)
                    for (let pv of wholeParameterValues) {
                        if (pv.parameterID == val.parameterID)
                            values.push({
                                parameterValueID: pv.parameterValueID,
                                value: pv.value,
                                colorCode: pv.colorCode,
                                checked: val.parameterValueID == pv.parameterValueID ? true : false,
                            });
                    }

                if (checkParam || checkParam == 0) {
                    for (let pv of newParameters[checkParam].values) {
                        if (pv.parameterValueID == val.parameterValueID) pv.checked = true;
                    }
                } else {
                    newParameters.push({
                        name: val.parameter.name,
                        isColor: val.parameter.isColor,
                        parameterID: val.parameterID,
                        values: values,
                        hideInProductDetail: val.hideInProductDetail,
                        forFeeds: val.forFeeds,
                        value: val.value,
                        priority: val.priority,
                    });
                }
            }
        }

        return newParameters;
    };

    return {
        loading: loading,
        error: error,
        allLanguageMutations,
        allManufacturers,
        allVats,
    };
};

export default UseGetProduct;
