import { useQuery } from "@apollo/client";
import { GET_PARAMETER_TEMPLATE } from "../../Queries/parameterTemplates";

const UseGetParameterTemplate = (selectedParameterTemplateID, lang, CallBack) => {
    const { loading, error } = useQuery(GET_PARAMETER_TEMPLATE, {
        variables: {
            lang: lang,
            parameterTemplateID: selectedParameterTemplateID,
        },
        fetchPolicy: "network-only",
        onCompleted: (d) => {
            if (selectedParameterTemplateID != 0) {
                var selectedParameters = [];

                if (d.ParameterTemplate.parameters)
                    for (let val of d.ParameterTemplate.parameters) {
                        selectedParameters.push({
                            parameterID: val.parameterID,
                            name: val.parameter.name,
                            priority: val.priority,
                        });
                    }

                CallBack({
                    name: d.ParameterTemplate.name,
                    selectedParameters: selectedParameters,
                });
            }
        },
    });

    return {
        loading: loading,
        error: error,
    };
};

export default UseGetParameterTemplate;
