import { useState } from "react";
import Input from "../../../../GlobalComponents/Input";
import Select from "../../../../GlobalComponents/Select";
import InputCard from "../../../../GlobalComponents/InputCard";
import Button from "../../../../GlobalComponents/Button";
import { TINY_MCE_TOOLBAR_BASIC } from "../../../../Config";
import TinyMCEEditor from "../../../TinyMCEEditor/Components/TinyMCEEditor";
import { useTranslation } from "react-i18next";
import List from "../../../../GlobalComponents/List/List";
import flagIcon from "../../../../Media/Images/Icons/flag.webp";
import priceIcon from "../../../../Media/Images/Icons/price.webp";
import labelIcon from "../../../../Media/Images/Icons/label.webp";
import tickLabelIcon from "../../../../Media/Images/Icons/tick.webp";
import Checkbox from "../../../../GlobalComponents/Checkbox";
import RadioButton from "../../../../GlobalComponents/RadioButton";
import UseEditCategories from "../../Library/Products/UseEditCategories";
import UseEditLabels from "../../Library/Products/UseEditLabels";
import UseEditTickLabels from "../../Library/Products/UseEditTickLabels";
import categoryIcon from "../../../../Media/Images/Icons/category.webp";
import parametersIcon from "../../../../Media/Images/Icons/parameters.webp";
import dimensionsIcon from "../../../../Media/Images/Icons/ruler.webp";
import cancelIcon from "../../../../Media/Images/Icons/cancel.webp";
import seo from "../../../../Media/Images/Icons/seo.webp";
import ChooseCategories from "./ChooseCategories";
import ChooseParameters from "./ChooseParameters";
import ChooseDimensionType from "./ChooseDimensionType";
import ChooseLabels from "./ChooseLabels";
import ChooseTickLabels from "./ChooseTickLabels";
import NoItems from "../../../../GlobalComponents/NoItems";
import { SERVER_URL, PRODUCT_DIMENSION_TYPE_IMAGE_URL } from "../../../../Config";
import Textarea from "../../../../GlobalComponents/Textarea";
import ChooseParametersFromTemplate from "./ChooseParametersFromTemplate";
import UseGetProductSeoData from "../../Library/Products/UseGetProductSeoData";
import { UseNotification } from "../../../Notification/Library/UseNotification";

const BasicInformations = ({
    formData,
    allManufacturers,
    allVats,
    SetFormLangData,
    GetFormLangValue,
    SetFormData,
    GeneratePriceAfterDiscount,
    FillFormSelectedItemsWithObj,
    RemoveFormDataSelectedItems,
    SetFormDataSelectedItemsWithObj,
    SetBooleanFormDataSelectedItemsWithObj,
    SetFormDataSelectedItemsPriority,
}) => {
    const { t } = useTranslation();
    const { AddNotification } = UseNotification();
    const [reloadDimensionPanel, SetReloadDimensionPanel] = useState(false);
    const [showAddCategories, SetShowAddCategories] = useState(false);
    const [showAddParameters, SetShowAddParameters] = useState(false);
    const [showParameterTemplatess, SetShowParameterTemplates] = useState(false);
    const [showAddDimensionType, SetShowAddDimensionType] = useState(false);
    const [showAddLabels, SetShowAddLabels] = useState(false);
    const [showAddTickLabels, SetShowAddTickLabels] = useState(false);

    const { headerData, content } = UseEditCategories(formData.selectedCategories, SetBooleanFormDataSelectedItemsWithObj, SetFormDataSelectedItemsWithObj);
    const { headerData: labelHeaderData, content: labelContent } = UseEditLabels(formData.selectedLabels, SetFormDataSelectedItemsWithObj);
    const { headerData: tickLabelHeaderData, content: tickLabelContent } = UseEditTickLabels(formData.selectedTickLabels, SetFormDataSelectedItemsWithObj);

    const { hName, hNameWithParameters, hManufacturer, hDescription } = UseGetProductSeoData(GetFormLangValue, allManufacturers, formData);

    return (
        <>
            <InputCard className="form-group" title={t("Main")} icon={flagIcon} canBeMinimalized={true}>
                <div className="row">
                    <div className="col-25">
                        <div className="form-group">
                            <label>{t("ShowOnEshop")} </label>
                            <div className="radio-container">
                                <RadioButton
                                    text="Ano"
                                    name="showOnEshop"
                                    id="showOnEshop-1"
                                    value={1}
                                    checked={formData.showOnEshop === 1 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                                <RadioButton
                                    text="Ne"
                                    name="showOnEshop"
                                    id="showOnEshop-2"
                                    value={0}
                                    checked={formData.showOnEshop === 0 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-25">
                        <div className="form-group">
                            <label>{t("AvailableForCart")} </label>
                            <div className="radio-container">
                                <RadioButton
                                    text="Ano"
                                    name="availableForCart"
                                    id="availableForCart-1"
                                    value={1}
                                    checked={formData.availableForCart === 1 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                                <RadioButton
                                    text="Ne"
                                    name="availableForCart"
                                    id="availableForCart-2"
                                    value={0}
                                    checked={formData.availableForCart === 0 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-25">
                        <div className="form-group">
                            <label>{t("HideCovers")} </label>
                            <div className="radio-container">
                                <RadioButton
                                    text="Ano"
                                    name="hideCovers"
                                    id="hideCovers-1"
                                    value={1}
                                    checked={formData.hideCovers === 1 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                                <RadioButton
                                    text="Ne"
                                    name="hideCovers"
                                    id="hideCovers-2"
                                    value={0}
                                    checked={formData.hideCovers === 0 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-25">
                        <div className="form-group">
                            <label>{t("HideCorpus")} </label>
                            <div className="radio-container">
                                <RadioButton
                                    text="Ano"
                                    name="hideCorpus"
                                    id="hideCorpus-1"
                                    value={1}
                                    checked={formData.hideCorpus === 1 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                                <RadioButton
                                    text="Ne"
                                    name="hideCorpus"
                                    id="hideCorpus-2"
                                    value={0}
                                    checked={formData.hideCorpus === 0 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={"col-50"}>
                        <div className="form-group">
                            <label>
                                *{t("ProductName")} ({formData.selectedLang})
                            </label>
                            <Input name="name" value={GetFormLangValue("name")} OnChange={(e) => SetFormLangData(e)} />
                        </div>
                    </div>

                    <div className={"col-50"}>
                        <div className="form-group">
                            <label>*{t("Manufacturer")}</label>
                            <Select name="manufacturerID" value={formData.manufacturerID} OnChange={(e) => SetFormData(e)}>
                                <option value={0}>{t("---choose manufacturer---")}</option>
                                {allManufacturers.map((item) => (
                                    <option key={item.manufacturerID} value={item.manufacturerID}>
                                        {item.name}
                                    </option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
            </InputCard>

            <InputCard className="form-group" title={t("Price")} icon={priceIcon} canBeMinimalized={true}>
                <div className="row">
                    <div className={"col-100"}>
                        <div className="form-group">
                            <Checkbox
                                name="dontOverwritePriceWithVariant"
                                id="dontOverwritePriceWithVariant"
                                text={"Nepřepisovat tuto cenu cenou z varianty"}
                                checked={formData.dontOverwritePriceWithVariant}
                                OnChange={(e) => SetFormData(e)}
                            />
                        </div>
                    </div>

                    <div className={"col-25"}>
                        <div className="form-group">
                            <label>
                                *{t("PriceWithVAT")} ({formData.selectedLang})
                            </label>
                            <Input name="price" value={GetFormLangValue("price")} OnChange={(e) => SetFormLangData(e)} />
                        </div>
                    </div>

                    <div className={"col-25"}>
                        <div className="form-group">
                            <label>*{t("VAT")}</label>
                            <Select name="vatID" value={formData.vatID} OnChange={(e) => SetFormData(e)}>
                                {allVats.map((item) => (
                                    <option key={item.vatID} value={item.vatID}>
                                        {item.value}%
                                    </option>
                                ))}
                            </Select>
                        </div>
                    </div>

                    <div className={"col-25"}>
                        <div className="form-group">
                            <label>
                                {t("Discount")} ({formData.selectedLang})
                            </label>
                            <Input
                                name="discount"
                                value={GetFormLangValue("discount")}
                                OnChange={(e) => SetFormLangData(e)}
                                append={
                                    <Checkbox
                                        text={"%"}
                                        id="d-p"
                                        name="isDiscountPercentage"
                                        checked={GetFormLangValue("isDiscountPercentage")}
                                        OnChange={(e) => SetFormLangData(e)}
                                    />
                                }
                            />
                        </div>
                    </div>

                    <div className={"col-25"}>
                        <div className="form-group">
                            <label>
                                {t("PriceWithVatAfterDiscount")} ({formData.selectedLang})
                            </label>
                            <div className="form-text">{GeneratePriceAfterDiscount()}</div>
                        </div>
                    </div>

                    <div className={"col-25"}>
                        <div className="form-group">
                            <label>
                                {t("CommonPrice")} ({formData.selectedLang})
                            </label>
                            <Input name="commonPrice" value={GetFormLangValue("commonPrice")} OnChange={(e) => SetFormLangData(e)} />
                        </div>
                    </div>
                </div>
            </InputCard>

            <InputCard
                className="form-group"
                title={"*" + t("Categories")}
                icon={categoryIcon}
                noBodyPadding={true}
                canBeMinimalized={true}
                headerChildren={
                    <Button
                        OnClick={(e) => {
                            e.stopPropagation();
                            SetShowAddCategories(true);
                        }}
                        className="btn-primary narrow"
                    >
                        {content && content.length > 0 ? t("Edit") : t("Choose")}
                    </Button>
                }
            >
                <List
                    headerData={headerData}
                    headerClassName={"no-top-border"}
                    contentData={content}
                    isSortable={false}
                    loading={false}
                    replaceDotsImg={cancelIcon}
                    ReplaceDotsOnClick={(data) => SetFormDataSelectedItemsWithObj("deleted", "selectedCategories", "linkID", data.rowID, true)}
                />
            </InputCard>

            <div className="form-group">
                <label>
                    {t("Description")} ({formData.selectedLang})
                </label>
                <TinyMCEEditor
                    value={GetFormLangValue("description")}
                    OnEditorChange={(content) => SetFormLangData("description", content)}
                    toolbar={TINY_MCE_TOOLBAR_BASIC}
                />
            </div>

            <InputCard
                className="form-group"
                title={t("TickLabels")}
                icon={tickLabelIcon}
                noBodyPadding={true}
                canBeMinimalized={true}
                headerChildren={
                    <Button
                        OnClick={(e) => {
                            e.stopPropagation();
                            SetShowAddTickLabels(true);
                        }}
                        className="btn-primary narrow"
                    >
                        {formData.selectedTickLabels.length > 0 ? t("Edit") : t("Choose")}
                    </Button>
                }
            >
                <List
                    headerData={tickLabelHeaderData}
                    headerClassName={"no-top-border"}
                    contentData={tickLabelContent}
                    isSortable={false}
                    loading={false}
                    replaceDotsImg={cancelIcon}
                    ReplaceDotsOnClick={(data) => RemoveFormDataSelectedItems("selectedTickLabels", "tickLabelID", [data.rowID])}
                />
            </InputCard>

            <InputCard
                className="form-group"
                title={t("Parameters")}
                icon={parametersIcon}
                canBeMinimalized={true}
                headerInfoContent={[t("ParameterInputCardInfo1"), t("ParameterInputCardInfo2"), t("ParameterInputCardInfo3")]}
                headerChildren={
                    <>
                        <Button
                            OnClick={(e) => {
                                e.stopPropagation();
                                SetShowParameterTemplates(true);
                            }}
                            className="btn-primary narrow"
                        >
                            {t("AddFromTemplate")}
                        </Button>

                        <Button
                            OnClick={(e) => {
                                e.stopPropagation();
                                SetShowAddParameters(true);
                            }}
                            className="btn-primary narrow"
                        >
                            {formData.selectedParameters.length > 0 ? t("Edit") : t("Choose")}
                        </Button>
                    </>
                }
            >
                {formData.selectedParameters.map((item, index) => (
                    <InputCard
                        key={index}
                        className="form-group"
                        title={item.name}
                        headerChildren={
                            <>
                                <div className="d-flex align-items-center product-parameter-priority">
                                    <label>{t("Priority")}:</label>
                                    <div className="d-flex">
                                        <Select
                                            className="narrow"
                                            name="priority"
                                            value={index}
                                            OnChange={(e) => {
                                                SetFormDataSelectedItemsPriority("selectedParameters", index, parseInt(e.target.value));
                                                AddNotification(t("OrderPriorityChangedDonForgetToSafeProduct"), true);
                                            }}
                                        >
                                            {formData.selectedParameters.map((_, optionIndex) => (
                                                <option key={optionIndex} value={optionIndex}>
                                                    {optionIndex + 1}
                                                </option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                                <img
                                    onClick={() => RemoveFormDataSelectedItems("selectedParameters", "parameterID", [item.parameterID])}
                                    className="no-margin cursor-pointer"
                                    src={cancelIcon}
                                />
                            </>
                        }
                    >
                        <div className="form-group d-flex">
                            <Checkbox
                                text={t("HideInProductDetail")}
                                id={"p-" + item.parameterID + "-h"}
                                name="hideInProductDetail"
                                checked={item.hideInProductDetail}
                                OnChange={(e) => SetFormDataSelectedItemsWithObj(e, "selectedParameters", "parameterID", item.parameterID)}
                            />

                            <Checkbox
                                text={t("ForFeeds")}
                                id={"p-" + item.parameterID + "-f"}
                                name="forFeeds"
                                checked={item.forFeeds}
                                OnChange={(e) => SetFormDataSelectedItemsWithObj(e, "selectedParameters", "parameterID", item.parameterID)}
                            />
                        </div>

                        <hr />

                        <div className="form-group">
                            {item.values && item.values.length > 0 ? (
                                <div className="row">
                                    {item.values.map((v, index) => {
                                        return (
                                            <div key={index} className="col-25 form-group">
                                                <Checkbox
                                                    isColor={item.isColor}
                                                    text={v.value}
                                                    colorCode={v.colorCode}
                                                    id={"p-" + item.parameterID + "-" + v.parameterValueID + "-f"}
                                                    name={"p-" + item.parameterID + "-" + v.parameterValueID}
                                                    checked={v.checked}
                                                    OnChange={(e) => {
                                                        var newArr = [...item.values];
                                                        newArr[index] = { ...item.values[index], checked: e.target.checked };
                                                        SetFormDataSelectedItemsWithObj(
                                                            "values",
                                                            "selectedParameters",
                                                            "parameterID",
                                                            item.parameterID,
                                                            newArr
                                                        );
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <>
                                    <label>{t("ParameterValue")}</label>
                                    <Input
                                        name={"value"}
                                        value={item.value}
                                        OnChange={(e) => SetFormDataSelectedItemsWithObj(e, "selectedParameters", "parameterID", item.parameterID)}
                                    />
                                </>
                            )}
                        </div>
                    </InputCard>
                ))}

                {formData.selectedParameters.length == 0 ? <NoItems text={t("NoItems")} /> : null}
            </InputCard>

            <InputCard
                className="form-group"
                title={t("Dimensions") + (formData.productDimensionType ? " - " + formData.productDimensionType.name : "")}
                icon={dimensionsIcon}
                canBeMinimalized={true}
                reload={reloadDimensionPanel}
                headerChildren={
                    <Button
                        OnClick={(e) => {
                            e.stopPropagation();
                            SetShowAddDimensionType(true);
                        }}
                        className="btn-primary narrow"
                    >
                        {formData.productDimensionType ? t("Edit") : t("Choose")}
                    </Button>
                }
            >
                {formData.productDimensionType ? (
                    <div className="product-dimension-type">
                        <img
                            onLoad={(d) => SetReloadDimensionPanel(!reloadDimensionPanel)}
                            src={SERVER_URL + "/" + PRODUCT_DIMENSION_TYPE_IMAGE_URL + "/" + formData.productDimensionType.photo}
                        />

                        {formData.productDimensionType.sizePositions.map((item, index) => (
                            <Input
                                key={index}
                                style={{ left: item.x + "%", top: item.y + "%" }}
                                type="text"
                                name="value"
                                value={item.value ? item.value : ""}
                                OnChange={(e) => {
                                    var newArr = [...formData.productDimensionType.sizePositions];
                                    newArr[index] = { ...newArr[index], value: e.target.value };

                                    const newDimensionType = { ...formData.productDimensionType, sizePositions: newArr };
                                    SetFormData("productDimensionType", newDimensionType);
                                }}
                            />
                        ))}
                    </div>
                ) : (
                    <NoItems text={t("DimensionsNotSelectedYet")} />
                )}
            </InputCard>

            <InputCard
                className="form-group"
                title={t("Labels")}
                icon={labelIcon}
                noBodyPadding={true}
                canBeMinimalized={true}
                headerInfoContent={[t("LabelInputCardInfo")]}
                headerChildren={
                    <Button
                        OnClick={(e) => {
                            e.stopPropagation();
                            SetShowAddLabels(true);
                        }}
                        className="btn-primary narrow"
                    >
                        {formData.selectedLabels.length > 0 ? t("Edit") : t("Choose")}
                    </Button>
                }
            >
                <List
                    headerData={labelHeaderData}
                    headerClassName={"no-top-border"}
                    contentData={labelContent}
                    isSortable={false}
                    loading={false}
                    replaceDotsImg={cancelIcon}
                    ReplaceDotsOnClick={(data) => RemoveFormDataSelectedItems("selectedLabels", "labelID", [data.rowID])}
                />
            </InputCard>

            <InputCard title={t("SEO")} icon={seo} canBeMinimalized={true}>
                <div className="row">
                    <div className="col-50">
                        <div className="form-group">
                            <label>
                                {t("SeoUrl")} ({formData.selectedLang})
                            </label>
                            <Input name="seoUrl" type="text" value={GetFormLangValue("seoUrl")} OnChange={(e) => SetFormLangData(e)} />
                        </div>
                    </div>
                    <div className="col-50">
                        <div className="form-group">
                            <label>
                                {t("MetaTitle")} ({formData.selectedLang})
                            </label>
                            <Input name="metaTitle" type="text" value={GetFormLangValue("metaTitle")} OnChange={(e) => SetFormLangData(e)} />
                        </div>
                    </div>
                    <div className="col-100">
                        <div className="form-group">
                            <label>
                                {t("MetaDescription")} ({formData.selectedLang})
                            </label>
                            <Input name="metaDescription" type="text" value={GetFormLangValue("metaDescription")} OnChange={(e) => SetFormLangData(e)} />
                        </div>
                    </div>
                </div>

                <InputCard title={t("Heuréka")} className="form-group">
                    <div className="row">
                        <div className="col-50">
                            <div className="form-group">
                                <label>
                                    {t("ProductName")} ({formData.selectedLang})
                                </label>
                                <Input name="hName" type="text" value={hName} OnChange={(e) => SetFormLangData(e)} />
                            </div>
                        </div>
                        <div className="col-50">
                            <div className="form-group">
                                <label>
                                    {t("ProductNameWithParameters")} ({formData.selectedLang})
                                </label>
                                <Input name="hNameWithParameters" value={hNameWithParameters} OnChange={(e) => SetFormLangData(e)} />
                            </div>
                        </div>
                        <div className="col-100">
                            <div className="form-group">
                                <label>
                                    {t("Categories")} ({formData.selectedLang})
                                </label>
                                <Input name="hCategory" type="text" value={GetFormLangValue("hCategory")} OnChange={(e) => SetFormLangData(e)} />
                            </div>
                        </div>

                        <div className="col-50">
                            <div className="form-group">
                                <label>{t("Manufacturer")}</label>
                                <Input name="hManufacturer" type="text" value={hManufacturer} OnChange={(e) => SetFormData(e)} />
                            </div>
                        </div>
                        <div className="col-100">
                            <div className="form-group">
                                <label>
                                    {t("Description")} ({formData.selectedLang})
                                </label>
                                <Textarea name={"hDescription"} value={hDescription} OnChange={(e) => SetFormLangData(e)} />
                            </div>
                        </div>
                    </div>
                </InputCard>
            </InputCard>

            {showAddCategories ? (
                <ChooseCategories
                    formData={formData}
                    useDeletedAttribute={true}
                    SetShowAddCategories={SetShowAddCategories}
                    FillFormSelectedItemsWithObj={FillFormSelectedItemsWithObj}
                />
            ) : null}

            {showAddParameters ? (
                <ChooseParameters formData={formData} SetShowAddParameters={SetShowAddParameters} FillFormSelectedItemsWithObj={FillFormSelectedItemsWithObj} />
            ) : null}

            {showParameterTemplatess ? (
                <ChooseParametersFromTemplate formData={formData} SetShowParameterTemplates={SetShowParameterTemplates} SetFormData={SetFormData} />
            ) : null}

            {showAddDimensionType ? (
                <ChooseDimensionType formData={formData} SetShowAddDimensionType={SetShowAddDimensionType} SetFormData={SetFormData} />
            ) : null}

            {showAddLabels ? (
                <ChooseLabels formData={formData} SetShowAddLabels={SetShowAddLabels} FillFormSelectedItemsWithObj={FillFormSelectedItemsWithObj} />
            ) : null}

            {showAddTickLabels ? (
                <ChooseTickLabels formData={formData} SetShowAddTickLabels={SetShowAddTickLabels} FillFormSelectedItemsWithObj={FillFormSelectedItemsWithObj} />
            ) : null}
        </>
    );
};

export default BasicInformations;
