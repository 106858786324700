import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_EDIT_LABEL } from "../../Queries/labels";

const UseAddEditLabel = (selectedLabelID,lang,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {formData, SetFormData, SetCompleteFormData,SetFormLangData,GetFormLangValue} = UseFormHandle({
        selectedLang:lang,
        active:1,
        isPlace:0,
        isFreeDelivery:0,
        langs:[]
    });

    const [AddEditLabelMutation,{error,loading}] = useMutation(ADD_EDIT_LABEL,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditLabel);

            const notifyText = (selectedLabelID != 0 ? t("SuccessfullyUpdated") : t("NewLabelIsInTheWorld"));

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditLabel = () => {

        var check = true;
        for(let val of formData.langs)
        {
            if(val.name === "" && val.lang === lang)
                check = false;
        }

        if(check)
        { 
            let langs = [];
            for(const lang of formData.langs)
            {
                langs.push({
                    name: lang.name,
                    lang: lang.lang
                });
            }
            AddEditLabelMutation({
                variables:{
                    labelID:        selectedLabelID,
                    active:         formData.active,
                    isPlace:        formData.isPlace,
                    isFreeDelivery: formData.isFreeDelivery,
                    langs:          langs
                }
            })
        }
        else
            AddNotification(t("NoNameNoGo"),false); 
        
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditLabel,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue
    }

}

export default UseAddEditLabel;