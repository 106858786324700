import Modal from "react-modal";
import { useState } from "react";
import Button from "../../../../GlobalComponents/Button";
import Input from "../../../../GlobalComponents/Input";
import ModalHeader from "../../../../GlobalComponents/Modal/ModalHeader";
import { useTranslation } from "react-i18next";
import category from "../../../../Media/Images/Icons/category.webp";
import UseAddEditParameterTemplate from "../../Library/ParameterTemplates/UseAddEditParameterTemplate";
import UseGetParameterTemplate from "../../Library/ParameterTemplates/UseGetParameterTemplate";
import Loading from "../../../../GlobalComponents/Loading";
import Error from "../../../../GlobalComponents/Error";
import InputCard from "../../../../GlobalComponents/InputCard";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseAuth } from "../../../Auth/Library/UseAuth";
import { useApolloClient } from "@apollo/client";
import NoItems from "../../../../GlobalComponents/NoItems";
import cancelIcon from "../../../../Media/Images/Icons/cancel.webp";
import RadioButton from "../../../../GlobalComponents/RadioButton";
import ChooseParameters from "../Products/ChooseParameters";
import Select from "../../../../GlobalComponents/Select";
import { UseNotification } from "../../../Notification/Library/UseNotification";

Modal.setAppElement("#root");

const AddEditParameterTemplate = ({ selectedParameterTemplateID, SetSelectedParameterTemplateID, OnAddEditSuccess }) => {
    const [showAddParameters, SetShowAddParameters] = useState(false);

    const { AddNotification } = UseNotification();
    const client = useApolloClient();
    const { user } = UseAuth();
    const { t } = useTranslation();
    const {
        loading,
        error,
        formData,
        AddEditParameterTemplate,
        InitFormData,
        SetFormData,
        FillFormSelectedItemsWithObj,
        RemoveFormDataSelectedItems,
        SetFormDataSelectedItemsPriority,
    } = UseAddEditParameterTemplate(selectedParameterTemplateID, (d) => OnAddEditSuccess(d));

    const { loading: getLoading, error: getError } = UseGetParameterTemplate(selectedParameterTemplateID, user.lang, (d) => {
        InitFormData(d);
    });

    var err = "";
    if (error || getError) err = GetApolloErrorText(error || getError);

    return (
        <Modal isOpen={true} onRequestClose={() => SetSelectedParameterTemplateID(null)} overlayClassName={"modal-overlay"} className={"modal-content"}>
            <ModalHeader
                title={selectedParameterTemplateID === 0 ? t("AddParameterTemplate") : t("EditParameterTemplate")}
                OnClose={() => SetSelectedParameterTemplateID(null)}
            />

            <div className="modal-body">
                {err ? (
                    <Error className="no-margin" text={err} />
                ) : (
                    <>
                        {loading || getLoading ? (
                            <Loading />
                        ) : (
                            <div>
                                <div className="form-group">
                                    <label>*{t("ParameterTemplateName")}</label>
                                    <Input name="name" value={formData.name} OnChange={(e) => SetFormData(e)} />
                                </div>

                                <InputCard
                                    title={t("Parameters")}
                                    icon={category}
                                    headerChildren={
                                        <Button OnClick={(e) => SetShowAddParameters(true)} className="btn-primary narrow">
                                            {formData.selectedParameters && formData.selectedParameters.length > 0 ? t("Edit") : t("Choose")}
                                        </Button>
                                    }
                                >
                                    {formData.selectedParameters.length > 0 ? (
                                        <div className="row">
                                            {formData.selectedParameters.map((item, index) => {
                                                return (
                                                    <div key={item.parameterID} className="col-33">
                                                        <div className="form-group d-flex">
                                                            <Select
                                                                className="template-param-priority"
                                                                name="priority"
                                                                value={index}
                                                                OnChange={(e) => {
                                                                    SetFormDataSelectedItemsPriority("selectedParameters", index, parseInt(e.target.value));
                                                                    AddNotification(t("OrderPriorityChangedDonForgetToSafeTemplate"), true);
                                                                }}
                                                            >
                                                                {formData.selectedParameters.map((_, optionIndex) => (
                                                                    <option key={optionIndex} value={optionIndex}>
                                                                        {optionIndex + 1}
                                                                    </option>
                                                                ))}
                                                            </Select>
                                                            <Input
                                                                disabled={true}
                                                                value={item.name}
                                                                append={
                                                                    <img
                                                                        onClick={() =>
                                                                            RemoveFormDataSelectedItems("selectedParameters", "parameterID", [item.parameterID])
                                                                        }
                                                                        className="cursor-pointer"
                                                                        src={cancelIcon}
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <NoItems text={t("ClickToAddNewParameters")} />
                                    )}
                                </InputCard>
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="modal-footer">
                <Button className={"btn-primary w-100"} OnClick={() => AddEditParameterTemplate()}>
                    {selectedParameterTemplateID === 0 ? t("Add") : t("Edit")}
                </Button>
            </div>

            {showAddParameters ? (
                <ChooseParameters formData={formData} SetShowAddParameters={SetShowAddParameters} FillFormSelectedItemsWithObj={FillFormSelectedItemsWithObj} />
            ) : null}
        </Modal>
    );
};

export default AddEditParameterTemplate;
