const Checkbox = ({
    text,
    id,
    name,
    checked,
    style,
    isColor,
    colorCode,
    OnChange
}) => {

    return(
        <div className={"checkbox" + (checked ? " checked" : "")} style = {style}>
            <input 
                id={id} 
                type="checkbox" 
                name={name} 
                checked = {checked}
                onChange = {(e) => OnChange(e)} 
            />
            {text ?
                <label 
                    htmlFor={id}
                    className={isColor ? "color" : ""}
                >
                    {isColor ? 
                        <span 
                            style={{backgroundColor:colorCode}}
                            title = {text}
                        ></span> 
                    : 
                        text
                    }
                </label>
            :null}
        </div>
    )
}

export default Checkbox;