import Modal from "react-modal";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import Button from "../../../../GlobalComponents/Button";
import ModalHeader from "../../../../GlobalComponents/Modal/ModalHeader";
import Checkbox from "../../../../GlobalComponents/Checkbox";
import { useTranslation } from "react-i18next";
import Error from "../../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import Loading from "../../../../GlobalComponents/Loading";
import { GET_PARAMETER_TEMPLATES } from "../../Queries/parameterTemplates";
import { UseAuth } from "../../../Auth/Library/UseAuth";

const ChooseParametersFromTemplate = ({ formData, SetShowParameterTemplates, SetFormData }) => {
    const { t } = useTranslation();
    const { user } = UseAuth();
    const [selectedTemplates, SetSelectedTemplates] = useState([]);

    const { data, loading, error } = useQuery(GET_PARAMETER_TEMPLATES, {
        variables: {
            lang: user.lang,
            limit: 1000000,
            offset: 0,
        },
        fetchPolicy: "network-only",
    });

    console.log(data);

    const SelectTempalte = (parameterTemplate) => {
        SetSelectedTemplates((prevValue) => {
            var arr = [...prevValue];
            var checked = false;

            for (let i in arr) {
                if (arr[i].parameterTemplateID == parameterTemplate.parameterTemplateID) {
                    checked = true;
                    arr.splice(i, 1);
                }
            }

            if (!checked) arr.push(parameterTemplate);

            return arr;
        });
    };

    const ChooseTemplates = () => {
        // Projdi každou vybranou šablonu a aktualizuj/přidej parametry
        const currentParameters = [...formData.selectedParameters];
        for (let val of selectedTemplates) {
            if (val.parameters && val.parameters.length > 0) {
                for (let p of val.parameters) {
                    const idx = currentParameters.findIndex((sp) => sp.parameterID === p.parameterID);
                    // Pokud parametr existuje, aktualizuj jeho prioritu (v případě, že se změnila)
                    if (idx !== -1) {
                        //seřadíme pole pouze v případě, že je vybrán pouze jeden template
                        if (selectedTemplates.length == 1) {
                            currentParameters[idx].priority = p.priority;
                        }
                    } else {
                        currentParameters.push({
                            name: p.parameter.name,
                            parameterID: p.parameterID,
                            values: p.parameter.values,
                            hideInProductDetail: false,
                            forFeeds: true,
                            isColor: p.parameter.isColor,
                            value: "",
                            priority: selectedTemplates.length == 1 ? p.priority : 0,
                        });
                    }
                }
            }
        }

        // Nyní seřadíme celé pole selectedParameters podle parametru priority
        if (selectedTemplates.length == 1) {
            currentParameters.sort((a, b) => a.priority - b.priority);
        }
        SetFormData("selectedParameters", currentParameters);

        SetShowParameterTemplates(false);
    };

    return (
        <Modal isOpen={true} onRequestClose={() => SetShowParameterTemplates(false)} overlayClassName={"modal-overlay"} className={"modal-content"}>
            <ModalHeader title={t("ChooseParameterTemplates")} OnClose={() => SetShowParameterTemplates(false)} />

            <div className="modal-body">
                {error ? (
                    <Error text={GetApolloErrorText(error)} />
                ) : loading ? (
                    <Loading />
                ) : (
                    <>
                        {data.AllParameterTemplates && data.AllParameterTemplates.length > 0 ? (
                            <div className="row">
                                {data.AllParameterTemplates.map((item) => {
                                    var check = false;
                                    for (let val of selectedTemplates) {
                                        if (val.parameterTemplateID == item.parameterTemplateID) check = true;
                                    }

                                    return (
                                        <div key={item.parameterTemplateID} className="col-20">
                                            <div className="form-group">
                                                <Checkbox
                                                    name="parameters"
                                                    id={"p-" + item.parameterTemplateID}
                                                    checked={check}
                                                    OnChange={() => SelectTempalte(item)}
                                                    text={item.name}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : null}
                    </>
                )}
            </div>
            <div className="modal-footer">
                <Button className={"btn-primary w-100"} OnClick={() => ChooseTemplates()}>
                    {t("Choose")}
                </Button>
            </div>
        </Modal>
    );
};

export default ChooseParametersFromTemplate;
