import gql from 'graphql-tag';

export const ADD_EDIT_LABEL = gql`
    mutation AddEditLabel(
        $labelID:ID,
        $active:Int, 
        $isPlace:Int, 
        $isFreeDelivery:Int, 
        $langs:[LabelLangsInput]
    ){
        AddEditLabel(
            labelID:$labelID,
            active:$active, 
            isPlace:$isPlace, 
            isFreeDelivery:$isFreeDelivery, 
            langs:$langs
        ){
            labelID
            name
            active
        }
    }
`;

export const GET_LABEL = gql`
    query LabelWithLangs($labelID: ID){
        LabelWithLangs(labelID:$labelID){
            labelID
            active
            isPlace
            isFreeDelivery
            langs{
                lang
                name
            }    
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

export const GET_LABELS = gql`
    query AllLabels($lang: String,$limit:Int,$offset:Int){
        AllLabels(lang:$lang,limit:$limit,offset:$offset){
            labelID
            name
            active
        }
        AllLabelsCount
    }
`;

export const DELETE_LABELS = gql`
    mutation DeleteLabels($labelIDs:ID) {
        DeleteLabels(labelIDs:$labelIDs)
    }
`;