import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from "react-i18next";
import { ADD_EDIT_PRODUCT } from "../../Queries/products";
import { useRef } from "react";

const UseAddEditProduct = (selectedProductID, lang, OnCompleted) => {
    var continueToPhotos = useRef(false);
    var stayHere = useRef(false);

    const { AddNotification } = UseNotification();
    const { t } = useTranslation();

    const {
        formData,
        SetFormData,
        SetCompleteFormData,
        SetFormLangData,
        GetFormLangValue,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObj,
        SetBooleanFormDataSelectedItemsWithObj,
        RemoveFormDataSelectedItems,
        SetFormDataSelectedItemsPriority,
    } = UseFormHandle({
        selectedLang: lang,
        manufacturerID: 0,
        vatID: 1,
        availableForCart: 1,
        dontOverwritePriceWithVariant: false,
        showOnEshop: 1,
        hideCovers: 0,
        hideCorpus: 0,
        langs: [],
        productPhotos: [],
        selectedCategories: [],
        selectedParameters: [],
        selectedLabels: [],
        selectedTickLabels: [],
        selectedExcludedCovers: [],
        selectedCoversPercentage: [],
        productDimensionType: null,
        hDeliveryTime: "0",
        hManufacturer: "",
    });

    const [AddEditProductMutation, { error, loading }] = useMutation(ADD_EDIT_PRODUCT, {
        onCompleted: (d) => {
            if (OnCompleted) OnCompleted(d.AddEditProduct, continueToPhotos.current, stayHere.current);

            const notifyText = selectedProductID != 0 ? t("SuccessfullyUpdated") : t("NewProductIsInTheWorld");

            AddNotification(notifyText, true);

            continueToPhotos.current = false;
            stayHere.current = false;
        },
        onError: (err) => {
            AddNotification(GetApolloErrorText(err), false);
        },
    });

    const AddEditProduct = (conToPhotos, sHere) => {
        continueToPhotos.current = conToPhotos;
        stayHere.current = sHere;

        var checkPrice = true;
        var check = true;
        var checkParameters = true;

        for (let val of formData.langs) {
            var p = parseFloat(val.price);
            if (val.name === "" && val.lang === lang) check = false;
            if ((!p && p !== 0) || p < 0) checkPrice = false;
        }

        var checkMainPhoto = false;
        if (formData.productPhotos.length == 0) checkMainPhoto = true;
        else
            for (let cp of formData.productPhotos) {
                if (cp.isMain) checkMainPhoto = true;
            }

        if (formData.selectedParameters.length > 0) {
            const regex = /^\d+$/;

            for (let p of formData.selectedParameters) {
                if (p.parameterID == 1 || p.parameterID == 2) {
                    if (p.value) p.value = p.value.replace(/ /g, "");

                    if (!p.value || !regex.test(p.value)) checkParameters = false;
                }
            }
        }

        if (check) {
            if (checkParameters) {
                if (formData.manufacturerID != 0) {
                    if (checkPrice) {
                        if (formData.selectedCategories.length > 0) {
                            if (CheckMainCategory(formData.selectedCategories)) {
                                if (checkMainPhoto) {
                                    let langs = [];
                                    const selectedLinks = GetSelectedCategories(formData.selectedCategories);
                                    const selectedTickLabels = GetSelectedTickLabels(formData.selectedTickLabels);
                                    const selectedParameters = GetSelectedParameters(formData.selectedParameters);
                                    const productDimensionType = GetProductDimensionType(formData.productDimensionType);
                                    const selectedLabels = GetSelectedLabels(formData.selectedLabels);
                                    const selectedCoversPercentage = GetSelectedCoversPercentage(
                                        formData.selectedCoversPercentage,
                                        formData.selectedExcludedCovers
                                    );

                                    for (const lang of formData.langs) {
                                        langs.push({
                                            name: lang.name,
                                            price: parseFloat(lang.price) || 0,
                                            commonPrice: parseFloat(lang.commonPrice) || 0,
                                            discount: parseFloat(lang.discount) || 0,
                                            isDiscountPercentage: lang.isDiscountPercentage ? 1 : 0,
                                            description: lang.description,
                                            seoUrl: lang.seoUrl,
                                            metaTitle: lang.metaTitle,
                                            metaDescription: lang.metaDescription,
                                            hName: lang.hName,
                                            hNameWithParameters: lang.hNameWithParameters,
                                            hCategory: lang.hCategory,
                                            hDescription: lang.hDescription,
                                            lang: lang.lang,
                                        });
                                    }

                                    var prodPhotos = [];
                                    for (let val of formData.productPhotos) {
                                        val.isMain = val.isMain ? 1 : 0;
                                        val.priority = parseInt(val.priority);

                                        prodPhotos.push({
                                            productPhotoID: val.productPhotoID,
                                            isMain: val.isMain,
                                            isUpdated: val.isUpdated,
                                            priority: val.priority,
                                        });
                                    }

                                    //nastavíme prioritu parametrům podle seřazení v poli, protože mohly být 0, jelikož se priorita řešila až později a mohou mít nastavenou 0
                                    for (let i = 1; i <= selectedParameters.length; i++) {
                                        selectedParameters[i - 1].priority = i;
                                    }

                                    AddEditProductMutation({
                                        variables: {
                                            productID: selectedProductID,
                                            showOnEshop: parseInt(formData.showOnEshop),
                                            availableForCart: parseInt(formData.availableForCart),
                                            hideCovers: parseInt(formData.hideCovers),
                                            hideCorpus: parseInt(formData.hideCorpus),
                                            dontOverwritePriceWithVariant: formData.dontOverwritePriceWithVariant ? 1 : 0,
                                            manufacturerID: formData.manufacturerID,
                                            vatID: formData.vatID,
                                            hDeliveryTime: formData.hDeliveryTime,
                                            hManufacturer: formData.hManufacturer,
                                            langs: langs,
                                            productPhotos: prodPhotos,
                                            selectedLinks: selectedLinks,
                                            selectedTickLabels: selectedTickLabels,
                                            selectedParameters: selectedParameters,
                                            productDimensionType: productDimensionType,
                                            selectedLabels: selectedLabels,
                                            selectedExcludedCovers: formData.selectedExcludedCovers,
                                            selectedCoversPercentage: selectedCoversPercentage,
                                        },
                                    });
                                } else AddNotification(t("ItIsNecessaryToChooseMainPhoto"), false);
                            } else AddNotification(t("NoMainCategorySelected"), false);
                        } else AddNotification(t("PleaseChooseCategories"), false);
                    } else AddNotification(t("SomePriceIsInvalid"), false);
                } else AddNotification(t("PleaseChooseManufacturer"), false);
            } else AddNotification(t("ParameterDepthOrWidthHasInvalidValue"), false);
        } else AddNotification(t("NoNameNoGo"), false);
    };

    const CheckMainCategory = (selectedCategories) => {
        for (let val of selectedCategories) {
            if (val.isMain) return true;
        }
        return false;
    };

    const GetSelectedCategories = (selectedCategories) => {
        var categories = [];
        for (let val of selectedCategories) {
            var obj = {
                linkID: val.linkID,
                isMain: val.isMain ? 1 : 0,
            };

            if (val.add) obj.add = true;
            if (val.deleted) obj.deleted = true;
            if (val.updated) obj.updated = true;

            categories.push(obj);
        }

        return categories;
    };

    const GetSelectedTickLabels = (selectedTickLabels) => {
        var tickLabels = [];
        for (let val of selectedTickLabels) {
            tickLabels.push({
                tickLabelID: val.tickLabelID,
            });
        }

        return tickLabels;
    };

    const GetSelectedParameters = (selectedParameters) => {
        var parameters = [];
        for (let val of selectedParameters) {
            var values = [];
            for (let v of val.values) {
                if (v.checked) values.push(v.parameterValueID);
            }

            parameters.push({
                parameterID: val.parameterID,
                forFeeds: val.forFeeds ? 1 : 0,
                hideInProductDetail: val.hideInProductDetail ? 1 : 0,
                values: values,
                value: val.value ? val.value : "",
                priority: val.priority,
            });
        }

        return parameters;
    };

    const GetProductDimensionType = (selectedProductDimensionType) => {
        var sizePositions = [];
        if (selectedProductDimensionType) {
            for (let val of selectedProductDimensionType.sizePositions) {
                sizePositions.push({
                    productDimensionTypeSizePositionID: val.productDimensionTypeSizePositionID,
                    value: parseFloat(val.value),
                });
            }

            var productDimensionType = {
                productDimensionTypeID: selectedProductDimensionType.productDimensionTypeID,
                sizePositions: sizePositions,
            };
        }

        return productDimensionType;
    };

    const GetSelectedLabels = (selectedLabels) => {
        var labels = [];
        for (let val of selectedLabels) {
            labels.push({
                labelID: val.labelID,
                useDates: val.useDates ? 1 : 0,
                dateFrom: val.dateFrom,
                dateTo: val.dateTo,
            });
        }

        return labels;
    };

    const GetSelectedCoversPercentage = (selectedPercentageCovers, selectedExcludedCovers) => {
        var percentageCovers = [];
        for (let val of selectedPercentageCovers) {
            const percentage = parseInt(val.percentage);
            if (percentage && selectedExcludedCovers.indexOf(val.coverID) == -1)
                percentageCovers.push({
                    coverID: val.coverID,
                    percentage: percentage,
                });
        }

        return percentageCovers;
    };

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    };

    const GeneratePriceAfterDiscount = () => {
        for (let l of formData.langs) {
            if (l.lang == formData.selectedLang) {
                if (l.isDiscountPercentage) return l.price - Math.round((l.price / 100) * l.discount);
                else return l.price - l.discount;
            }
        }
    };

    return {
        error: error,
        loading: loading,
        formData,
        AddEditProduct,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObj,
        SetBooleanFormDataSelectedItemsWithObj,
        RemoveFormDataSelectedItems,
        GeneratePriceAfterDiscount,
        SetFormDataSelectedItemsPriority,
    };
};

export default UseAddEditProduct;
