import gql from "graphql-tag";

export const ADD_EDIT_LINK = gql`
    mutation AddEditLink(
        $linkID: ID
        $parentID: ID
        $isEshopCategory: Int
        $photo: Upload
        $shortcut: String
        $primarilySortBy: String
        $active: Int
        $inHeader: Int
        $inFooter: Int
        $isBlog: Int
        $showOnlyInStockProducts: Int
        $hideMenu: Int
        $usePriceFilter: Int
        $useDepthFilter: Int
        $useInStockFilter: Int
        $useWidthFilter: Int
        $langs: [LinkLangsInput]
        $selectedParameters: [LinkParameterInput]
    ) {
        AddEditLink(
            linkID: $linkID
            parentID: $parentID
            isEshopCategory: $isEshopCategory
            photo: $photo
            shortcut: $shortcut
            primarilySortBy: $primarilySortBy
            active: $active
            inHeader: $inHeader
            inFooter: $inFooter
            isBlog: $isBlog
            showOnlyInStockProducts: $showOnlyInStockProducts
            hideMenu: $hideMenu
            usePriceFilter: $usePriceFilter
            useDepthFilter: $useDepthFilter
            useWidthFilter: $useWidthFilter
            useInStockFilter: $useInStockFilter
            langs: $langs
            selectedParameters: $selectedParameters
        )
    }
`;

export const UPDATE_LINK_PRIORITY = gql`
    mutation UpdateLinkPriority($parentID: ID, $linkID: ID, $isEshopCategory: Int, $fromIndex: Int, $toIndex: Int) {
        UpdateLinkPriority(parentID: $parentID, linkID: $linkID, isEshopCategory: $isEshopCategory, fromIndex: $fromIndex, toIndex: $toIndex)
    }
`;

export const COPY_LINK = gql`
    mutation CopyLink($linkID: ID) {
        CopyLink(linkID: $linkID)
    }
`;

export const GET_LINK = gql`
    query LinkWithLangs($linkID: ID, $lang: String) {
        LinkWithLangs(linkID: $linkID) {
            linkID
            isEshopCategory
            photo
            shortcut
            active
            parentID
            inHeader
            inFooter
            isBlog
            showOnlyInStockProducts
            hideMenu
            usePriceFilter
            useDepthFilter
            useWidthFilter
            useInStockFilter
            primarilySortBy
            parameters {
                parameterID
                priority
                parameter(lang: $lang) {
                    name
                }
            }
            langs {
                lang
                name
                ownUrl
                description
                niceName
                metaTitle
                metaKeywords
                metaDescription
                metaImage
            }
        }
        AllLanguageMutations(onlyEnabled: true) {
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        AllParameters(lang: $lang, limit: 1000000, offset: 0) {
            parameterID
            name
            values {
                parameterValueID
            }
        }
    }
`;

export const GET_LINKS = gql`
    query AllLinks($lang: String, $isEshopCategory: Int) {
        AllLinks(lang: $lang, isEshopCategory: $isEshopCategory) {
            linkID
            name
            active
            parentID
            inHeader
            inFooter
            showOnlyInStockProducts
            hideMenu
            subLink {
                linkID
                name
                active
                parentID
                inHeader
                inFooter
                showOnlyInStockProducts
                hideMenu
                subLink {
                    linkID
                    name
                    active
                    parentID
                    inHeader
                    inFooter
                    showOnlyInStockProducts
                    hideMenu
                    subLink {
                        linkID
                        name
                        active
                        parentID
                        inHeader
                        inFooter
                        showOnlyInStockProducts
                        hideMenu
                        subLink {
                            linkID
                            name
                            active
                            parentID
                            inHeader
                            inFooter
                            showOnlyInStockProducts
                            hideMenu
                            subLink {
                                linkID
                                name
                                active
                                parentID
                                inHeader
                                inFooter
                                showOnlyInStockProducts
                                hideMenu
                                subLink {
                                    linkID
                                    name
                                    active
                                    parentID
                                    inHeader
                                    inFooter
                                    showOnlyInStockProducts
                                    hideMenu
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const DELETE_LINKS = gql`
    mutation DeleteLinks($linkIDs: ID) {
        DeleteLinks(linkIDs: $linkIDs)
    }
`;
