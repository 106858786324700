import { useState } from "react";
import Input from "../../../GlobalComponents/Input";
import { useTranslation } from "react-i18next";
import RadioButton from "../../../GlobalComponents/RadioButton";
import eye from "../../../Media/Images/Icons/eye.webp";
import filter from "../../../Media/Images/Icons/filter.webp";
import imageIcon from "../../../Media/Images/Icons/image.webp";
import FileInput from "../../../GlobalComponents/FileInput";
import InputCard from "../../../GlobalComponents/InputCard";
import SelectBox from "../../../GlobalComponents/SelectBox";
import TinyMCEEditor from "../../TinyMCEEditor/Components/TinyMCEEditor";
import Select from "../../../GlobalComponents/Select";
import { TINY_MCE_TOOLBAR_BASIC } from "../../../Config";
import NoItems from "../../../GlobalComponents/NoItems";
import Button from "../../../GlobalComponents/Button";
import ChooseParameters from "./ChooseParameters";
import cancelIcon from "../../../Media/Images/Icons/cancel.webp";
import { UseNotification } from "../../Notification/Library/UseNotification";

const CategoryForm = ({
    GetFormLangValue,
    SetFormLangData,
    SetFormData,
    formData,
    allLinks,
    OpenImage,
    RemoveFormDataSelectedItems,
    FillFormSelectedItemsWithObj,
    SetFormDataSelectedItemsPriority,
}) => {
    const { AddNotification } = UseNotification();
    const [showAddParameters, SetShowAddParameters] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <div className="row">
                <div className="col-40 d-flex">
                    <InputCard title={t("Image")} icon={imageIcon} className={"form-group flex-1"} topMargin={true}>
                        {formData.mainPhoto ? (
                            <div className="open-image-container form-group">
                                <img className="no-margin" src={formData.mainPhoto} />
                            </div>
                        ) : null}

                        <div className="form-group">
                            <FileInput name="photo" OnChange={(e) => OpenImage(e)} />
                        </div>
                    </InputCard>
                </div>

                <div className={"col-60"}>
                    <div className="row">
                        <div className={"col-100"}>
                            <div className="form-group">
                                <label>
                                    *{t("CategoryName")} ({formData.selectedLang})
                                </label>
                                <Input name="name" value={GetFormLangValue("name")} OnChange={(e) => SetFormLangData(e)} />
                            </div>
                        </div>

                        <div className={"col-50"}>
                            <div className="form-group">
                                <label>*{t("Shortcut")} </label>
                                <Input name="shortcut" value={formData.shortcut} OnChange={(e) => SetFormData(e)} />
                            </div>
                        </div>

                        <div className="col-50">
                            <div className="form-group">
                                <label>*{t("ParentCategory")}</label>
                                <SelectBox
                                    value={formData.parentID}
                                    OnChange={(value) => SetFormData("parentID", value)}
                                    nameParam="name"
                                    valueParam="linkID"
                                    subParam="subLink"
                                    items={allLinks}
                                />
                            </div>
                        </div>

                        <div className="col-50">
                            <div className="form-group">
                                <label>{t("PrimarilySortBy")}</label>
                                <Select value={formData.primarilySortBy} OnChange={(e) => SetFormData(e)} name="primarilySortBy">
                                    <option value={"fav"}>{t("Favorite")}</option>
                                    <option value={"che"}>{t("Cheapest")}</option>
                                    <option value={"exp"}>{t("MostExpensive")}</option>
                                    <option value={"nam"}>{t("ByName")}</option>
                                    <option value={"sol"}>{t("BestSeller")}</option>
                                    <option value={"pri"}>{t("News")}</option>
                                </Select>
                            </div>
                        </div>

                        <div className="col-100">
                            <InputCard title={t("Display")} icon={eye} className={"form-group"}>
                                <div className="row">
                                    <div className="col-33">
                                        <div className="form-group">
                                            <label>{t("Active")} </label>
                                            <div className="radio-container">
                                                <RadioButton
                                                    text="Ano"
                                                    name="active"
                                                    id="active-1"
                                                    value={1}
                                                    checked={formData.active === 1 ? true : false}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                                <RadioButton
                                                    text="Ne"
                                                    name="active"
                                                    id="active-2"
                                                    value={0}
                                                    checked={formData.active === 0 ? true : false}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-33">
                                        <div className="form-group">
                                            <label>{t("InHeader")} </label>
                                            <div className="radio-container">
                                                <RadioButton
                                                    text="Ano"
                                                    name="inHeader"
                                                    id="inHeader-1"
                                                    value={1}
                                                    checked={formData.inHeader === 1 ? true : false}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                                <RadioButton
                                                    text="Ne"
                                                    name="inHeader"
                                                    id="inHeader-2"
                                                    value={0}
                                                    checked={formData.inHeader === 0 ? true : false}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-33">
                                        <div className="form-group">
                                            <label>{t("OnlyInStockProducts")} </label>
                                            <div className="radio-container">
                                                <RadioButton
                                                    text="Ano"
                                                    name="showOnlyInStockProducts"
                                                    id="showOnlyInStockProducts-1"
                                                    value={1}
                                                    checked={formData.showOnlyInStockProducts === 1 ? true : false}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                                <RadioButton
                                                    text="Ne"
                                                    name="showOnlyInStockProducts"
                                                    id="showOnlyInStockProducts-2"
                                                    value={0}
                                                    checked={formData.showOnlyInStockProducts === 0 ? true : false}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </InputCard>
                        </div>
                    </div>
                </div>
            </div>

            <div className="form-group">
                <label>
                    {t("CategoryDescription")} ({formData.selectedLang})
                </label>
                <TinyMCEEditor
                    value={GetFormLangValue("description")}
                    OnEditorChange={(content) => SetFormLangData("description", content)}
                    toolbar={TINY_MCE_TOOLBAR_BASIC}
                />
            </div>

            <div className="form-group">
                <InputCard
                    title={t("Filters/Parameters")}
                    icon={filter}
                    headerChildren={
                        <Button OnClick={(e) => SetShowAddParameters(true)} className="btn-primary narrow">
                            {formData.selectedParameters && formData.selectedParameters.length > 0 ? t("Edit") : t("Choose")}
                        </Button>
                    }
                >
                    <div className="row">
                        <div className="col-25">
                            <div className="form-group">
                                <label>{t("UsePriceFilter")} </label>
                                <div className="radio-container">
                                    <RadioButton
                                        text="Ano"
                                        name="usePriceFilter"
                                        id="usePriceFilter-1"
                                        value={1}
                                        checked={formData.usePriceFilter === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text="Ne"
                                        name="usePriceFilter"
                                        id="usePriceFilter-2"
                                        value={0}
                                        checked={formData.usePriceFilter === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-25">
                            <div className="form-group">
                                <label>{t("UseWidthFilter")} </label>
                                <div className="radio-container">
                                    <RadioButton
                                        text="Ano"
                                        name="useWidthFilter"
                                        id="useWidthFilter-1"
                                        value={1}
                                        checked={formData.useWidthFilter === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text="Ne"
                                        name="useWidthFilter"
                                        id="useWidthFilter-2"
                                        value={0}
                                        checked={formData.useWidthFilter === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-25">
                            <div className="form-group">
                                <label>{t("UseDepthFilter")} </label>
                                <div className="radio-container">
                                    <RadioButton
                                        text="Ano"
                                        name="useDepthFilter"
                                        id="useDepthFilter-1"
                                        value={1}
                                        checked={formData.useDepthFilter === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text="Ne"
                                        name="useDepthFilter"
                                        id="useDepthFilter-2"
                                        value={0}
                                        checked={formData.useDepthFilter === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-25">
                            <div className="form-group">
                                <label>{t("UseInStockFilter")} </label>
                                <div className="radio-container">
                                    <RadioButton
                                        text="Ano"
                                        name="useInStockFilter"
                                        id="useInStockFilter-1"
                                        value={1}
                                        checked={formData.useInStockFilter === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text="Ne"
                                        name="useInStockFilter"
                                        id="useInStockFilter-2"
                                        value={0}
                                        checked={formData.useInStockFilter === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <hr />
                        <p>{t("ChooseParametersToFilter")}</p>
                    </div>

                    {formData.selectedParameters.length > 0 ? (
                        <div className="row">
                            {formData.selectedParameters.map((item, index) => {
                                return (
                                    <div key={item.parameterID} className="col-33">
                                        <div className="form-group d-flex">
                                            <Select
                                                className="template-param-priority"
                                                name="priority"
                                                value={index}
                                                OnChange={(e) => {
                                                    SetFormDataSelectedItemsPriority("selectedParameters", index, parseInt(e.target.value));
                                                    AddNotification(t("OrderPriorityChangedDonForgetToSafeCategory"), true);
                                                }}
                                            >
                                                {formData.selectedParameters.map((_, optionIndex) => (
                                                    <option key={optionIndex} value={optionIndex}>
                                                        {optionIndex + 1}
                                                    </option>
                                                ))}
                                            </Select>
                                            <Input
                                                disabled={true}
                                                value={item.name}
                                                append={
                                                    <img
                                                        onClick={() => RemoveFormDataSelectedItems("selectedParameters", "parameterID", [item.parameterID])}
                                                        className="cursor-pointer"
                                                        src={cancelIcon}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <NoItems text={t("ClickToAddNewParameters")} />
                    )}
                </InputCard>
            </div>

            {showAddParameters ? (
                <ChooseParameters formData={formData} SetShowAddParameters={SetShowAddParameters} FillFormSelectedItemsWithObj={FillFormSelectedItemsWithObj} />
            ) : null}
        </>
    );
};

export default CategoryForm;
