import { useQuery } from "@apollo/client";
import { useState } from "react";
import { CompleteLangsArray } from "../../../../GlobalFunctions/helper";
import { GET_COUPON } from "../../Queries/coupons";
import dayjs from "dayjs";

const UseGetCoupon = (selectedCouponID,lang,CallBack) => {

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [allVats, SetAllVats] = useState([]);

    const {loading,error} = useQuery(GET_COUPON,{
        variables:{
            couponID:selectedCouponID,
            lang
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                amount:0,  
                text:"",                  
                inPercentage:true,
                minimumOrderPrice:0
            }

            if(selectedCouponID != 0)
            { 
                let langs = CompleteLangsArray(d.CouponWithLangs.langs,d.AllLanguageMutations,emptyLang);

                CallBack({
                    selectedLang:                       (d.CouponWithLangs.forLangID != 0 ? d.CouponWithLangs.forLang.suffix  : lang), 
                    couponID:                           d.CouponWithLangs.couponID,
                    vatID:                              d.CouponWithLangs.vatID,
                    forLangID:                          d.CouponWithLangs.forLangID,
                    isLimitedQuantity:                  d.CouponWithLangs.isLimitedQuantity,
                    forInStockProducts:                 d.CouponWithLangs.forInStockProducts,
                    byManufacturer:                     d.CouponWithLangs.byManufacturer,
                    byLink:                             d.CouponWithLangs.byLink,
                    canBeAppliedToDiscountedProducts:   d.CouponWithLangs.canBeAppliedToDiscountedProducts,
                    showInProductDetail:                d.CouponWithLangs.showInProductDetail,
                    quantity:                           d.CouponWithLangs.quantity,
                    code:                               d.CouponWithLangs.code,
                    dateFrom:                           dayjs(d.CouponWithLangs.dateFrom).format("YYYY-MM-DD"),
                    dateTo:                             dayjs(d.CouponWithLangs.dateTo).format("YYYY-MM-DD"),
                    internalDescription:                d.CouponWithLangs.internalDescription,
                    langs:                              langs,
                    selectedManufacturers:              GetManufacturers(d.CouponWithLangs.manufacturers),
                    selectedCategories:                 GetSelectedCategories(d.CouponWithLangs.links),
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);

                CallBack({
                    langs:langs
                });
            }

            SetAllLanguageMutations(d.AllLanguageMutations);
            SetAllVats(d.AllVats);
        }
    });

    const GetManufacturers = (manufacturers) => {
        var selectedManufacturers = [];
        for(let val of manufacturers)
        {
            selectedManufacturers.push({
                manufacturerID: val.manufacturerID,
                name: val.manufacturer.name
            });
        }

        return selectedManufacturers;
    }

    const GetSelectedCategories = (links) => {
        var categories = [];
        for(let val of links)
        {
            categories.push({
                linkID: val.linkID,
                name: (val.link ? val.link.name : "ODKAZ SMAZÁN")
            })
        }

        return categories;
    }

    return{
        loading: loading,
        error: error,
        allLanguageMutations,
        allVats
    }

}

export default UseGetCoupon;