import Modal from 'react-modal';
import Button from '../../../../GlobalComponents/Button';
import ModalHeader from '../../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditCoupon from '../../Library/Coupons/UseAddEditCoupon.js';
import UseGetCoupon from '../../Library/Coupons/UseGetCoupon.js';
import Loading from '../../../../GlobalComponents/Loading';
import Error from '../../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../../GlobalFunctions/helper';
import { UseAuth } from '../../../Auth/Library/UseAuth';
import { useApolloClient } from '@apollo/client';
import { useState } from 'react';
import InputCard from '../../../../GlobalComponents/InputCard';
import eyeIcon from '../../../../Media/Images/Icons/eye.webp';
import priceIcon from '../../../../Media/Images/Icons/price.webp';
import Input from '../../../../GlobalComponents/Input';
import RadioButton from '../../../../GlobalComponents/RadioButton';
import Checkbox from '../../../../GlobalComponents/Checkbox';
import Select from '../../../../GlobalComponents/Select';
import categoryIcon from '../../../../Media/Images/Icons/category.webp';
import UseEditCategories from '../../Library/Products/UseEditCategories';
import List from '../../../../GlobalComponents/List/List';
import cancelIcon from '../../../../Media/Images/Icons/cancel.webp';
import ChooseCategories from '../Products/ChooseCategories';
import manufacturersIcon from '../../../../Media/Images/Icons/factory.webp';
import UseEditManufacturers from '../../Library/Covers/UseEditManufacturers';
import ChooseManufacturers from '../Covers/ChooseManufacturers';
import DatePicker from '../../../../GlobalComponents/DatePicker';

Modal.setAppElement('#root');

const AddEditCoupon = ({selectedCouponID,SetSelectedCouponID}) => {

    const [showAddCategories, SetShowAddCategories] = useState(false);
    const [showAddManufacturer, SetShowAddManufacturer] = useState(false);

    const client = useApolloClient();
    const {user} = UseAuth();
    const {t} = useTranslation();

    const {
        allLanguageMutations,
        allVats,
        loading:getLoading,
        error:getError,
    } = UseGetCoupon(selectedCouponID,user.lang,(d) => {
        InitFormData(d)
    });

    const {
        loading,
        error,
        formData,
        AddEditCoupon,
        InitFormData,
        SetFormData,
        SetFormLangData,
        GetFormLangValue,
        SetFormDataSelectedItemsWithObj,
        SetBooleanFormDataSelectedItemsWithObj,
        RemoveFormDataSelectedItems,
        FillFormSelectedItemsWithObj
    } = UseAddEditCoupon(selectedCouponID,user.lang,allLanguageMutations,(d) => OnAddEditSuccess(d));

    const {
        headerData,
        content
    } = UseEditCategories(
        formData.selectedCategories,
        SetBooleanFormDataSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObj,
        true
    );
    
    const {
        headerData:manufacturerHeaderData,
        content:manufacturerContent
    } = UseEditManufacturers(
        formData.selectedManufacturers,
        SetFormDataSelectedItemsWithObj,
        true
    );

    const OnAddEditSuccess = async (d) => {

        SetSelectedCouponID(null);
        
        await client.refetchQueries({
            include: ["AllCoupons"],
        });
    }

    const SetForLang = (languageID) => {

        var lang = null;
        for(let val of allLanguageMutations)
        {
            if(val.languageID == languageID)
                lang = val;
        }

        SetFormData("forLangID",languageID);
        if(lang)
            SetFormData("selectedLang",lang.suffix);
    }

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedCouponID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {(selectedCouponID === 0 ? t("AddCoupon") : t("EditCoupon"))}
                allLanguages = {allLanguageMutations}
                OnClose ={() => SetSelectedCouponID(null)}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <Loading />
                        : 
                            <>                                                   
                                <InputCard
                                    className = "form-group"
                                    title = {t("Usage")}
                                    icon = {eyeIcon}
                                >   
                                    <div className="row">
                                        <div className="col-25">
                                            <div className="form-group">
                                                
                                                <label>{t("ByManufacturer")} </label>
                                                <div className="radio-container">
                                                    <RadioButton
                                                        text="Ano"
                                                        name="byManufacturer"
                                                        id="byManufacturer-1"
                                                        value={1}
                                                        checked = {formData.byManufacturer === 1 ? true : false}
                                                        OnChange={(e) => SetFormData(e)}
                                                    />
                                                    <RadioButton
                                                        text="Ne"
                                                        name="byManufacturer"
                                                        id="byManufacturer-2"
                                                        value={0}
                                                        checked = {formData.byManufacturer === 0 ? true : false}
                                                        OnChange={(e) => SetFormData(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-25">
                                            <div className="form-group">
                                                
                                                <label>{t("ByLink")} </label>
                                                <div className="radio-container">
                                                    <RadioButton
                                                        text="Ano"
                                                        name="byLink"
                                                        id="byLink-1"
                                                        value={1}
                                                        checked = {formData.byLink === 1 ? true : false}
                                                        OnChange={(e) => SetFormData(e)}
                                                    />
                                                    <RadioButton
                                                        text="Ne"
                                                        name="byLink"
                                                        id="byLink-2"
                                                        value={0}
                                                        checked = {formData.byLink === 0 ? true : false}
                                                        OnChange={(e) => SetFormData(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-25">
                                            <div className="form-group">
                                                
                                                <label>{t("ShowInProductDetail")} </label>
                                                <div className="radio-container">
                                                    <RadioButton
                                                        text="Ano"
                                                        name="showInProductDetail"
                                                        id="showInProductDetail-1"
                                                        value={1}
                                                        checked = {formData.showInProductDetail === 1 ? true : false}
                                                        OnChange={(e) => SetFormData(e)}
                                                    />
                                                    <RadioButton
                                                        text="Ne"
                                                        name="showInProductDetail"
                                                        id="showInProductDetail-2"
                                                        value={0}
                                                        checked = {formData.showInProductDetail === 0 ? true : false}
                                                        OnChange={(e) => SetFormData(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-25">
                                            <div className="form-group">
                                                
                                                <label>{t("LimitedQuantity")} </label>
                                                <div className="radio-container">
                                                    <RadioButton
                                                        text="Ano"
                                                        name="isLimitedQuantity"
                                                        id="isLimitedQuantity-1"
                                                        value={1}
                                                        checked = {formData.isLimitedQuantity === 1 ? true : false}
                                                        OnChange={(e) => SetFormData(e)}
                                                    />
                                                    <RadioButton
                                                        text="Ne"
                                                        name="isLimitedQuantity"
                                                        id="isLimitedQuantity-2"
                                                        value={0}
                                                        checked = {formData.isLimitedQuantity === 0 ? true : false}
                                                        OnChange={(e) => SetFormData(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-25">
                                            <div className="form-group">
                                                
                                                <label>{t("CanBeAppliedToDiscountedProducts")} </label>
                                                <div className="radio-container">
                                                    <RadioButton
                                                        text="Ano"
                                                        name="canBeAppliedToDiscountedProducts"
                                                        id="canBeAppliedToDiscountedProducts-1"
                                                        value={1}
                                                        checked = {formData.canBeAppliedToDiscountedProducts === 1 ? true : false}
                                                        OnChange={(e) => SetFormData(e)}
                                                    />
                                                    <RadioButton
                                                        text="Ne"
                                                        name="canBeAppliedToDiscountedProducts"
                                                        id="canBeAppliedToDiscountedProducts-2"
                                                        value={0}
                                                        checked = {formData.canBeAppliedToDiscountedProducts === 0 ? true : false}
                                                        OnChange={(e) => SetFormData(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-25">
                                            <div className="form-group">
                                                
                                                <label>{t("ForInStockProducts")} </label>
                                                <div className="radio-container">
                                                    <RadioButton
                                                        text="Ano"
                                                        name="forInStockProducts"
                                                        id="forInStockProducts-1"
                                                        value={1}
                                                        checked = {formData.forInStockProducts === 1 ? true : false}
                                                        OnChange={(e) => SetFormData(e)}
                                                    />
                                                    <RadioButton
                                                        text="Ne"
                                                        name="forInStockProducts"
                                                        id="forInStockProducts-2"
                                                        value={0}
                                                        checked = {formData.forInStockProducts === 0 ? true : false}
                                                        OnChange={(e) => SetFormData(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-25">
                                            <div className="form-group">
                                                
                                                <label>{t("ForLangs")} </label>
                                                <div className="form-group">
                                                    <Select
                                                        value={formData.forLangID}
                                                        name="forLangID"
                                                        OnChange={(e) => SetForLang(e.target.value)}
                                                    >
                                                        <option value="0">{t("all")}</option>
                                                        {allLanguageMutations && allLanguageMutations.map((item) => (
                                                            <option 
                                                                key={item.languageID} 
                                                                value={item.languageID}
                                                            >{item.suffix}</option>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </InputCard>

                                <div className="row">
                                    <div className={(formData.isLimitedQuantity ? "col-50" : "col-100")}>
                                        <div className="form-group">
                                            <label>*{t("CouponCode")}</label>
                                            <Input
                                                name="code"
                                                value={formData.code}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                        </div>
                                    </div>
                                    {formData.isLimitedQuantity ?
                                        <div className={"col-50"}>
                                            <div className="form-group">
                                                <label>*{t("Quantity")}</label>
                                                <Input
                                                    name="quantity"
                                                    value={formData.quantity}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                            </div>
                                        </div>
                                    :null}
                                    <div className={"col-100"}>
                                        <div className="form-group">
                                            <label>{t("Text")} ({formData.selectedLang})</label>
                                            <Input
                                                name="text"
                                                value={GetFormLangValue("text")}
                                                OnChange={(e) => SetFormLangData(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="form-group">
                                            <label>*{t("ValidityFrom")}</label>
                                            <DatePicker 
                                                OnChange = {(_,date) => SetFormData("dateFrom",date)}
                                                value = {formData.dateFrom}
                                                showPast = {true}
                                                showLegend = {false}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="form-group">
                                            <label>*{t("ValidityTo")}</label>
                                            <DatePicker 
                                                OnChange = {(_,date) => SetFormData("dateTo",date)}
                                                value = {formData.dateTo}
                                                showPast = {true}
                                                showLegend = {false}
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-100"}>
                                        <div className="form-group">
                                            <label>{t("InternaDescription")}</label>
                                            <Input
                                                name="internalDescription"
                                                value={formData.internalDescription}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <InputCard
                                    className = {(formData.byManufacturer || formData.byLink  ? "form-group" : "")}
                                    title = {t("Amount")}
                                    icon = {priceIcon}
                                    canBeMinimalized = {true}
                                >   
                                    <div className="row">

                                        <div className={"col-25"}>
                                                    
                                            <div className="form-group">
                                                <label>*{t("AmountWithVat")} ({formData.selectedLang})</label>
                                                <Input
                                                    name="amount"
                                                    value={GetFormLangValue("amount")}
                                                    OnChange={(e) => SetFormLangData(e)}
                                                    append = {
                                                        <Checkbox
                                                            text = {"%"}
                                                            id = "d-p"
                                                            name = "inPercentage"
                                                            checked = {GetFormLangValue("inPercentage")}
                                                            OnChange={(e) => SetFormLangData(e)}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div> 

                                        <div className={"col-25"}>          
                                            <div className="form-group">
                                                <label>*{t("VAT")}</label>
                                                <Select
                                                    name="vatID"
                                                    value={formData.vatID}
                                                    OnChange={(e) => SetFormData(e)}
                                                >
                                                    {allVats.map((item) => (
                                                        <option 
                                                            key = {item.vatID}
                                                            value = {item.vatID}
                                                        >{item.value}%</option>
                                                    ))}
                                                    
                                                </Select>
                                            </div>
                                        </div> 

                                        <div className={"col-50"}>
                                                    
                                            <div className="form-group">
                                                <label>{t("MinimumOrderPrice")} ({formData.selectedLang})</label>
                                                <Input
                                                    name="minimumOrderPrice"
                                                    value={GetFormLangValue("minimumOrderPrice")}
                                                    OnChange={(e) => SetFormLangData(e)}
                                                />
                                            </div>
                                        </div> 

                                        
                                    </div>
                                </InputCard>

                                {formData.byLink ?
                                    <InputCard
                                        className = {(formData.byManufacturer ? "form-group" : "")}
                                        title = {"*" + t("Categories")}
                                        icon = {categoryIcon}
                                        noBodyPadding = {true}
                                        canBeMinimalized = {true}
                                        headerChildren = {
                                            <Button 
                                                OnClick = {(e) => {
                                                    e.stopPropagation();
                                                    SetShowAddCategories(true)
                                                }}
                                                className="btn-primary narrow"
                                            >{content && content.length > 0 ? t("Edit") : t("Choose")}</Button>
                                        }
                                    >   
                                        <List 
                                            headerData = {headerData} 
                                            headerClassName = {"no-top-border"}
                                            contentData = {content}
                                            isSortable = {false}
                                            loading = {false}
                                            replaceDotsImg = {cancelIcon}
                                            ReplaceDotsOnClick = {(data) => SetFormDataSelectedItemsWithObj("deleted","selectedCategories","linkID",data.rowID,true)}
                                        />
                                    
                                    </InputCard>
                                :null}

                                {formData.byManufacturer ?
                                    <InputCard
                                        title = {"*" + t("Manufacturers")}
                                        icon = {manufacturersIcon}
                                        noBodyPadding = {true}
                                        headerChildren = {
                                            <Button 
                                                OnClick = {() => SetShowAddManufacturer(true)}
                                                className="btn-primary narrow"
                                            >{content && content.length > 0 ? t("Edit") : t("Choose")}</Button>
                                        }
                                    >   
                                        <List 
                                            headerData = {manufacturerHeaderData} 
                                            headerClassName = {"no-top-border"}
                                            contentData = {manufacturerContent}
                                            isSortable = {false}
                                            loading = {false}
                                            replaceDotsImg = {cancelIcon}
                                            ReplaceDotsOnClick = {(data) => RemoveFormDataSelectedItems("selectedManufacturers","manufacturerID",[data.rowID])}
                                        />
                                    
                                    </InputCard>
                                :null}
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => AddEditCoupon()}
                >
                    {(selectedCouponID === 0 ? t("Add") : t("Edit"))}
                </Button>
                
            </div>

            {showAddCategories ? 
                <ChooseCategories 
                    formData = {formData}
                    useDeletedAttribute = {true}
                    SetShowAddCategories = {SetShowAddCategories}
                    FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                />
            :null}

            {showAddManufacturer ? 
                <ChooseManufacturers 
                    formData = {formData}
                    SetShowAddManufacturer = {SetShowAddManufacturer}
                    FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                />
            :null}

        </Modal>
    )
}

export default AddEditCoupon;