import { useState } from "react";
import Modal from "react-modal";
import Button from "../../../GlobalComponents/Button";
import Input from "../../../GlobalComponents/Input";
import ModalHeader from "../../../GlobalComponents/Modal/ModalHeader";
import { useTranslation } from "react-i18next";
import seo from "../../../Media/Images/Icons/seo.webp";
import FileInput from "../../../GlobalComponents/FileInput";
import UseAddEditLink from "../Library/UseAddEditLink";
import UseGetLink from "../Library/UseGetLink";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import InputCard from "../../../GlobalComponents/InputCard";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseAuth } from "../../Auth/Library/UseAuth";
import { useApolloClient } from "@apollo/client";
import { SERVER_URL, LINK_IMAGE_URL } from "../../../Config";
import LinkForm from "./LinkForm";
import CategoryForm from "./CategoryForm";
import LinkProducts from "./LinkProducts";

Modal.setAppElement("#root");

const AddEditLink = ({ selectedLinkID, SetSelectedLinkID, isEshopCategory }) => {
    const client = useApolloClient();
    const { user } = UseAuth();
    const { t } = useTranslation();

    const [selectedTab, SetSelectedTab] = useState(1);

    const {
        loading,
        error,
        formData,
        AddEditLink,
        InitFormData,
        SetFormData,
        SetFormLangData,
        GetFormLangValue,
        OpenLangImage,
        OpenImage,
        RemoveFormDataSelectedItems,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsPriority,
    } = UseAddEditLink(selectedLinkID, user.lang, (d) => OnAddEditSuccess(d));

    const {
        allLanguageMutations,
        allLinks,
        allParameters,
        loading: getLoading,
        error: getError,
    } = UseGetLink(selectedLinkID, user.lang, isEshopCategory, (d) => {
        var initData = { ...d, mainPhoto: d.mainPhoto ? SERVER_URL + "/" + LINK_IMAGE_URL + "/stredni_" + d.mainPhoto : "" };
        InitFormData(initData);
    });

    const OnAddEditSuccess = async (d) => {
        SetSelectedLinkID(null);
        await client.refetchQueries({
            include: ["AllLinks"],
        });
    };

    var err = "";
    if (error || getError) err = GetApolloErrorText(error || getError);

    return (
        <Modal isOpen={true} onRequestClose={() => SetSelectedLinkID(null)} overlayClassName={"modal-overlay"} className={"modal-content"}>
            <ModalHeader
                title={selectedLinkID === 0 ? (isEshopCategory ? t("AddCategory") : t("AddLink")) : isEshopCategory ? t("EditCategory") : t("EditLink")}
                allLanguages={allLanguageMutations}
                OnClose={() => SetSelectedLinkID(null)}
                selectedLang={formData.selectedLang}
                OnLangSelect={(e) => SetFormData(e)}
            />

            <div className="modal-body">
                {isEshopCategory ? (
                    <ul className="tabs">
                        <li className={selectedTab == 1 ? "selected" : ""} onClick={() => SetSelectedTab(1)}>
                            {t("BasicInformations")}
                        </li>
                        <li className={selectedTab == 2 ? "selected" : ""} onClick={() => SetSelectedTab(2)}>
                            {t("Products")}
                        </li>
                    </ul>
                ) : null}

                {err ? (
                    <Error className="no-margin" text={err} />
                ) : (
                    <>
                        {loading || getLoading ? (
                            <Loading />
                        ) : (
                            <>
                                {selectedTab == 1 ? (
                                    <>
                                        {isEshopCategory ? (
                                            <CategoryForm
                                                GetFormLangValue={GetFormLangValue}
                                                SetFormLangData={SetFormLangData}
                                                SetFormData={SetFormData}
                                                formData={formData}
                                                allLinks={allLinks}
                                                allParameters={allParameters}
                                                OpenImage={OpenImage}
                                                RemoveFormDataSelectedItems={RemoveFormDataSelectedItems}
                                                FillFormSelectedItemsWithObj={FillFormSelectedItemsWithObj}
                                                SetFormDataSelectedItemsPriority={SetFormDataSelectedItemsPriority}
                                            />
                                        ) : (
                                            <LinkForm
                                                GetFormLangValue={GetFormLangValue}
                                                SetFormLangData={SetFormLangData}
                                                SetFormData={SetFormData}
                                                formData={formData}
                                                allLinks={allLinks}
                                            />
                                        )}

                                        <InputCard title={t("SEO")} icon={seo}>
                                            <div className="row">
                                                <div className="col-50">
                                                    <div className="form-group">
                                                        <label>
                                                            {t("MetaTitle")} ({formData.selectedLang})
                                                        </label>
                                                        <Input name="metaTitle" value={GetFormLangValue("metaTitle")} OnChange={(e) => SetFormLangData(e)} />
                                                    </div>
                                                </div>
                                                <div className="col-50">
                                                    <div className="form-group">
                                                        <label>
                                                            {t("MetaKeys")} ({formData.selectedLang})
                                                        </label>
                                                        <Input
                                                            name="metaKeywords"
                                                            value={GetFormLangValue("metaKeywords")}
                                                            OnChange={(e) => SetFormLangData(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-100">
                                                    <div className="form-group">
                                                        <label>
                                                            {t("MetaDescription")} ({formData.selectedLang})
                                                        </label>
                                                        <Input
                                                            name="metaDescription"
                                                            value={GetFormLangValue("metaDescription")}
                                                            OnChange={(e) => SetFormLangData(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-100">
                                                    <div className="form-group">
                                                        <label>
                                                            {t("MetaImage")} ({formData.selectedLang})
                                                        </label>
                                                        <FileInput name="metaImage" OnChange={(e) => OpenLangImage(e)} />
                                                        <div className="open-image-container">
                                                            {GetFormLangValue("metaImageShow") ? <img src={GetFormLangValue("metaImageShow")} /> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </InputCard>
                                    </>
                                ) : null}
                                {selectedTab == 2 ? <LinkProducts lang={user.lang} selectedLinkID={selectedLinkID} /> : null}
                            </>
                        )}
                    </>
                )}
            </div>
            <div className="modal-footer">
                <Button className={"btn-primary w-100"} OnClick={() => AddEditLink()}>
                    {selectedLinkID === 0 ? t("Add") : t("Edit")}
                </Button>
            </div>
        </Modal>
    );
};

export default AddEditLink;
